<template lang="pug">
div
  loading(:active.sync="busy", :is-full-page="true")
  .widget
    .widget-header
      h1.title URL Scanner
    .widget-body
      form(@submit.prevent="scan")
        .form-group
          label URL
          input.form-control(type="text", v-model="form.url")
        .form-group
          label Country
          select.form-control(v-model="form.country")
            option(v-for="o in countriesOptions", :value="o.v") {{ o.t }}
        .form-group(v-if="form.country === 'US'")
          label Region
          select.form-control(v-model="form.region")
            option(v-for="o in usaRegionsOptions", :value="o.v") {{ o.t }}
        .form-group
          label OS
          select.form-control(v-model="form.os")
            option(v-for="o in osOptions", :value="o.v") {{ o.t }}
        .form-group
          label OS Version
          input.form-control(type="text", v-model="form.osVersion")
        button.btn.btn-primary(type="submit") Scan

      div.mt-2(v-if="redirects.length")
        h6 Redirects:
        ol
          li(v-for="redirect, index in redirects")
            a(:href="redirect.url", target="_blank") {{ redirect.url }}
            span.ml-3 ({{ redirect.statusCode }})

</template>
<script>
import Vue from 'vue';

export default {
  name: 'UrlScanner',
  data() {
    return {
      busy: false,
      redirects: [],
      countriesOptions: [],
      usaRegionsOptions: [],
      osOptions: [
        { v: null, t: '' },
        { v: 'ios', t: 'iOS' },
        { v: 'android', t: 'Android' }
      ],
      form: {
        url: '',
        country: null,
        region: null,
        os: null,
        osVersion: '',
      },
    };
  },
  async beforeRouteEnter(to, from, next) {
    const countriesOptions = await Vue.ovData.country.getOptions();
    const usaRegionsOptions = await Vue.ovData.geo.region.getOptionsByCountry(['us']);
    next((vm) => {
      vm.countriesOptions = [{ v: null, t: '' }]
        .concat(countriesOptions.map((o) => ({ v: o.code, t: o.name })));
      vm.usaRegionsOptions = [{ v: null, t: '' }]
        .concat(usaRegionsOptions.map((o) => ({ v: o.region_code, t: o.name })));
      return vm;
    });
  },
  methods: {
    async scan() {
      this.busy = true;
      try {
        const body = { ...this.form, osVersion: this.form.osVersion || null }
        const response = await this.$ovReq.post('url-scanner/scan', body);
        this.redirects = response.redirects;
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    }
  },
};
</script>
